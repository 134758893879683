export const STATUS = {
  CANCELLED: 0,
  PENDING: 1,
  CONFIRMED: 2,
  ATTENDED: 3,
  NO_SHOW: 4,
  RESCHEDULED: 5,
  EN_COMANDA: 7,
} as const;

export const STATUS_OPTIONS = [
  // {
  //   id: STATUS.CANCELLED,
  //   name: "Cancelada",
  //   color: "#616161",
  // },
  {
    id: STATUS.PENDING,
    name: "Pendiente",
    color: "#EDB003",
  },
  {
    id: STATUS.CONFIRMED,
    name: "Confirmada",
    color: "#7886CB",
  },
  {
    id: STATUS.ATTENDED,
    name: "Atendida",
    color: "#33B679",
  },
  {
    id: STATUS.NO_SHOW,
    name: "No se presentó",
    color: "#E67C73",
  },
  {
    id: STATUS.RESCHEDULED,
    name: "Reprogramada",
    color: "#4090B5",
  },
  {
    id: STATUS.EN_COMANDA,
    name: "En comanda",
    color: "#4090B5",
  },
];

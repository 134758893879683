import React, { useState, useEffect, useRef, useCallback } from "react";
// @ts-ignore
import { TextInput, IconWrapper } from "@viuti/recursos";
import { MagnifyingGlassIcon } from "@Models/icons";
import style from "./SearchSelect.module.css";
import { useDebounce } from "@Hooks/useDebounce/useDebounce";
import { HiXMark } from "react-icons/hi2";
import { createPortal } from "react-dom";

interface SearchOption {
  id: string | number;
  label: string;
  subtitle?: string;
  initials?: string;
}

interface SearchSelectProps {
  textInputProps: React.InputHTMLAttributes<HTMLInputElement> & {
    handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  };
  onSearch: (searchTerm: string) => Promise<void>;
  onSelect: (option: SearchOption | null) => void;
  options: SearchOption[];
  isLoading?: boolean;
  selectedOption?: {
    id: string;
    label: string;
    subtitle?: string;
  } | null;
  renderOption?: (
    option: SearchOption,
    onSelect: (option: SearchOption) => void
  ) => React.ReactNode;
  inputRef?: React.RefObject<HTMLInputElement>;
  onCreateNew?: () => void;
  createButtonLabel?: string;
  disabled?: boolean;
  selectedContainerClassName?: string;
}

const SearchSelect: React.FC<SearchSelectProps> = ({
  textInputProps,
  onSearch,
  onSelect,
  options,
  isLoading = false,
  selectedOption,
  renderOption,
  inputRef,
  onCreateNew,
  createButtonLabel = "Crear nuevo",
  disabled = false,
  selectedContainerClassName,
}) => {
  const [isResultsVisible, setIsResultsVisible] = useState(false);
  const debouncedSearchTerm = useDebounce(textInputProps.value as string, 300);
  const containerRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [placement, setPlacement] = useState<"top" | "bottom">("bottom");
  const observerRef = useRef<IntersectionObserver | null>(null);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  const calculatePosition = useCallback(() => {
    if (!containerRef.current) return;

    const rect = containerRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const optionsHeight = 250;

    // Calcular espacio disponible
    const spaceAbove = rect.top;
    const spaceBelow = viewportHeight - rect.bottom;

    // Determinar la posición óptima
    if (spaceBelow >= optionsHeight || spaceBelow >= spaceAbove) {
      setPlacement("bottom");
      setPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    } else {
      setPlacement("top");
      setPosition({
        top: rect.top + window.scrollY - optionsHeight,
        left: rect.left + window.scrollX,
      });
    }
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm) {
      onSearch(debouncedSearchTerm as string);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (!containerRef.current || !isResultsVisible) return;

    // Configurar IntersectionObserver
    observerRef.current = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          calculatePosition();
        }
      },
      {
        threshold: 1.0,
      }
    );

    // Configurar ResizeObserver
    resizeObserverRef.current = new ResizeObserver(() => {
      calculatePosition();
    });

    // Iniciar observación
    observerRef.current.observe(containerRef.current);
    resizeObserverRef.current.observe(containerRef.current);

    // Calcular posición inicial
    calculatePosition();

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [isResultsVisible, calculatePosition]);

  const defaultRenderOption = (option: SearchOption) => (
    <button
      key={option.id}
      className={style.option}
      onClick={() => handleOptionSelect(option)}
    >
      {option.initials && (
        <div className={style.option__initials}>{option.initials}</div>
      )}
      <div className={style.option__content}>
        <span className={style.option__label} title={option.label}>
          {option.label}
        </span>
        {option.subtitle && (
          <span className={style.option__subtitle}>{option.subtitle}</span>
        )}
      </div>
    </button>
  );

  const handleOptionSelect = (option: SearchOption) => {
    onSelect(option);
    setIsResultsVisible(false);
  };

  const handleClearSelection = () => {
    onSelect(null);
  };

  const renderOptions = () => {
    if (!isResultsVisible || options.length === 0) return null;

    return createPortal(
      <div
        className={`${style.options__portal} ${style[placement]}`}
        style={{
          position: "absolute",
          top: `${position.top}px`,
          left: `${position.left}px`,
          width: containerRef.current?.offsetWidth,
          maxHeight: "250px",
          zIndex: 2000,
        }}
      >
        {options.map((option) =>
          renderOption
            ? renderOption(option, handleOptionSelect)
            : defaultRenderOption(option)
        )}
      </div>,
      document.body
    );
  };

  if (selectedOption) {
    return (
      <div
        className={`${style.selected_container} ${selectedContainerClassName}`}
      >
        <div className={style.selected_content}>
          <div className={style.selected_info}>
            <span className={style.selected_label}>{selectedOption.label}</span>
            {selectedOption.subtitle && (
              <span className={style.selected_subtitle}>
                {selectedOption.subtitle}
              </span>
            )}
          </div>
        </div>
        {!disabled && (
          <button
            className={style.clear_button}
            onClick={handleClearSelection}
            type="button"
          >
            <IconWrapper icon={HiXMark} className={style.clear_icon} />
          </button>
        )}
      </div>
    );
  }

  return (
    <div className={style.search_wrapper}>
      <div ref={containerRef} className={style.search__container}>
        <div className={style.input__wrapper}>
          <TextInput
            label=""
            leftIcon={MagnifyingGlassIcon}
            inputRef={inputRef}
            width="100%"
            {...textInputProps}
            onFocus={(e) => {
              textInputProps?.onFocus?.(e);
              setIsResultsVisible(true);
            }}
            handleChange={(e) => {
              textInputProps?.handleChange?.(e);
              setIsResultsVisible(true);
            }}
            isLoading={isLoading}
          />
        </div>
        {renderOptions()}
      </div>
      {onCreateNew && (
        <button
          className={style.create_button}
          onClick={onCreateNew}
          type="button"
        >
          {createButtonLabel}
        </button>
      )}
    </div>
  );
};

export default SearchSelect;

export const GW_DOMAIN = "https://gw-qa.prikly.io";

export const billingBaseUrl = `${GW_DOMAIN}/facturacion/v1`;
export const promotionsBaseUrl = `${GW_DOMAIN}/promotions/v1`;

export const CashRegisterBaseUrl = `${GW_DOMAIN}/caja/v1`;
export const appointmentsBaseUrl = `${GW_DOMAIN}/reservas/v1`;
export const loungesBaseUrl = `${GW_DOMAIN}/lounges/v1`;
export const employeesBaseUrl = `${GW_DOMAIN}/empleados/v1`;
export const servicesBaseUrl = `${GW_DOMAIN}/servicios/v1`;
export const warehousesBaseUrl = `${GW_DOMAIN}/almacen/v1`;
export const oldBaseUrl = `${GW_DOMAIN}/viuti2/v1`;
export const clientsBaseUrl = `${GW_DOMAIN}/customers/v1`;

import React, { Dispatch, useEffect, useRef, useState } from "react";

// Components
// @ts-ignore
import Icon from "@Atoms/Icon/Icon";
import ButtonWithoutIcon from "@Atoms/buttons/buttonsWithoutIcons/button";
import { TimesIcon } from "@Models/icons";
import LoadingScreen from "@Molecules/LoadingScreen/LoadingScreen";

// Style
// @ts-ignore
import style from "./confirmModal.module.css";
import { STATUS_OK } from "@Models/httpsStatus";

interface ConfirmModalProps {
  title: string;
  message: object | string;
  buttonLbl: string;
  controller: {
    visible: boolean;
    setVisible: Dispatch<boolean>;
    isLoading: boolean;
    isDisabled: boolean;
  };
  confirmAction: any;
  successAction?: any;
}

const ConfirmModal = (Props: ConfirmModalProps) => {
  const {
    title,
    message,
    buttonLbl,
    controller,
    confirmAction,
    successAction = () => {},
  } = Props;
  const { visible, setVisible, isLoading, isDisabled } = controller;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const modal = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modal.current && !modal.current.contains(event.target)) {
        setVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modal, setVisible]);

  const handleConfirm = async () => {
    setIsSubmitting(true);
    if (typeof confirmAction === "function") {
      const response = await confirmAction();
      if (response.status === STATUS_OK || response.isSuccess) {
        setVisible(false);
        successAction(response);
      }
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className={`${style.layoutContainer} ${
        visible ? style.visible : style.hidden
      }`}
    >
      <div className={`${style.layoutContainer} `}>
        <div className={style.modalContainer} ref={modal}>
          <button
            className={style.closeButtonContainer}
            onClick={() => setVisible(false)}
            data-testid="close-btn"
          >
            <Icon path={TimesIcon} color={"#B4B4B4"} size={14} />
          </button>
          <p className={style.modalTitle}>{title}</p>
          {typeof message === "object" ? (
            message
          ) : (
            <p className={style.modalMessage}>{message}</p>
          )}
          <ButtonWithoutIcon
            isPrimary
            textBttn={buttonLbl}
            handleClick={handleConfirm}
            isLoading={isSubmitting || isLoading}
            isDisabled={isDisabled}
          />
          {/* {isLoading && <LoadingScreen visible loaderWidth={150} />} */}
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;

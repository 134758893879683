import React from "react";
import {
  TextInput,
  DateInput,
  // @ts-ignore
} from "@viuti/recursos";
import style from "./LotsCreationForm.module.css";
import SearchSelect from "@Molecules/SearchSelect/SearchSelect";

interface LotsCreationFormProps {
  handleInputChange: (e: any) => void;
  lotForm: {
    code: string;
    entryDate: string;
    expirationDate: string;
    units: number;
    modalityId: string;
    unitPrice: string;
    providerSearch: string;
    provider: {
      id: string;
      label: string;
      subtitle?: string;
    };
  };
  lotFormError: any;
  codeError: string;
  isCodeLoading: boolean;
  onSearchProvider: (searchTerm: string) => Promise<void>;
  providerOptions: Array<{
    id: string | number;
    label: string;
    subtitle?: string;
  }>;
  isSearchingProvider: boolean;
  onCreateProvider: () => void;
  isEditMode: boolean;
  isReadMode: boolean;
}

const LotsCreationForm = ({
  handleInputChange,
  lotForm,
  lotFormError,
  codeError,
  isCodeLoading,
  onSearchProvider,
  providerOptions,
  isSearchingProvider,
  onCreateProvider,
  isEditMode,
  isReadMode,
}: LotsCreationFormProps) => {
  const handleUnitPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputAmount = e.target.value;
    inputAmount = inputAmount.slice(0, 20);
    const formattedAmount = inputAmount.replace(/[^0-9.]/g, "");
    const isNumeric = /^[0-9.]*$/.test(formattedAmount);

    if (isNumeric) {
      handleInputChange({
        ...e,
        target: {
          ...e.target,
          name: "unitPrice",
          value: formattedAmount,
        },
      });
    }
  };

  const handleUnitPriceBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    let num = Number(lotForm.unitPrice);
    let formattedAmount;
    if (!isNaN(num)) {
      formattedAmount = num.toFixed(2);
    } else {
      formattedAmount = "0.00";
    }

    handleInputChange({
      ...e,
      target: {
        ...e.target,
        name: "unitPrice",
        value: formattedAmount,
      },
    });
  };

  return (
    <article className={style.form_container}>
      {/* Sección de Proveedor */}
      <section className={style.form_section}>
        {isReadMode ? (
          <h3 className={style.section_title}>Proveedor del lote</h3>
        ) : (
          <div className={style.providerLinkContainer}>
            Para crear un nuevo proveedor, haga click
            <a
              href="https://qa.prikly.io/proveedores"
              target="_blank"
              rel="noopener noreferrer"
              className={style.providerLink}
            >
              aquí
            </a>
            .
          </div>
        )}
        <div className={style.section_content}>
          <SearchSelect
            textInputProps={{
              placeholder: "Buscar proveedor por nombre o RUC",
              name: "providerSearch",
              value: lotForm.providerSearch,
              handleChange: (e) => {
                handleInputChange({
                  target: {
                    name: "providerSearch",
                    value: e.target.value,
                  },
                });
              },
            }}
            onSearch={onSearchProvider}
            onSelect={(provider) => {
              if (provider) {
                handleInputChange({
                  target: {
                    name: "provider",
                    value: provider,
                  },
                });
                handleInputChange({
                  target: {
                    name: "providerSearch",
                    value: "",
                  },
                });
              } else {
                handleInputChange({
                  target: {
                    name: "provider",
                    value: "",
                  },
                });
              }
            }}
            options={providerOptions}
            isLoading={isSearchingProvider}
            selectedOption={lotForm.provider}
            onCreateNew={onCreateProvider}
            createButtonLabel="Crear proveedor"
            disabled={isReadMode}
          />
        </div>
      </section>

      {/* Sección de Información del Lote */}
      <section className={style.form_section}>
        <h3 className={style.section_title}>Información del Lote</h3>
        <div className={style.section_content}>
          <div className={style.form_grid}>
            <TextInput
              label="Código"
              type="text"
              name="code"
              placeholder="Ingrese el código del lote"
              value={lotForm.code || ""}
              handleChange={handleInputChange}
              className={style.input}
              touched={true}
              error={!isCodeLoading ? lotFormError.code || codeError : ""}
              isLoading={isCodeLoading}
              required
              disabled={isReadMode}
            />
            <TextInput
              label="Unidades"
              type="number"
              name="units"
              placeholder="Ingrese la cantidad de unidades"
              value={lotForm.units}
              handleChange={handleInputChange}
              error={lotFormError.units}
              touched={true}
              className={style.input}
              required
              disabled={isReadMode}
            />
            <DateInput
              label="Fecha de ingreso"
              error={lotFormError.entryDate}
              touched={true}
              inputProps={{
                name: "entryDate",
                value: lotForm.entryDate,
                onChange: handleInputChange,
              }}
              required
              disabled={isReadMode}
            />
            <DateInput
              label="Fecha de vencimiento"
              error={lotFormError.expirationDate}
              touched={true}
              inputProps={{
                name: "expirationDate",
                value: lotForm.expirationDate,
                onChange: handleInputChange,
              }}
              required
              disabled={isReadMode}
            />
            <TextInput
              label="Costo unitario"
              prefix="S/"
              type="text"
              name="unitPrice"
              placeholder="Ingrese el precio por unidad"
              value={lotForm.unitPrice}
              handleChange={handleUnitPriceChange}
              inputProps={{
                onBlur: handleUnitPriceBlur,
              }}
              error={lotFormError.unitPrice}
              touched={true}
              className={style.input}
              required
              disabled={isReadMode}
            />
          </div>
        </div>
      </section>
    </article>
  );
};

export default LotsCreationForm;

import { putUpdateReserveDetail_service } from "@Services/reserve/putUpdateReserveDetail.service";

export const putUpdateReserveDetail_adapter = async (payload) => {
  const adaptEditReserveDetails = payload.editReserveDetails.map((detail) => ({
    reserveDetailId: detail.id,
    priceServiceId: detail.service,
    employeeId: detail.employee,
    reserveHour: detail.schedule,
  }));
  const adaptNewReserveDetails = payload.newReserveDetails.map((detail) => ({
    priceServiceId: detail.service,
    employeeId: detail.employee,
    reserveHour: detail.schedule,
    promoId: 0,
  }));

  const adaptRequest = {
    reserveId: payload.reserveId,
    newStatus: payload.newStatus,
    deleteReserveDetailsIds: payload.deleteReserveDetailsIds,
    newReserveDetails: adaptNewReserveDetails,
    editReserveDetails: adaptEditReserveDetails,
    observation: payload.observation,
    dateAttention: payload.dateAttention,
  };

  const response = await putUpdateReserveDetail_service(adaptRequest);
  if (!response.isSuccess) {
    return {
      ...response,
      data: null,
    };
  }
  return {
    ...response,
    data: response.data,
  };
};
